body {
  margin: 0;
  background-color: #fafafa !important;
  color: black !important;
  font-family: "Futura Std Book" !important;
  -webkit-font-smoothing: antialiased;
}

.ant-statistic-content-value,
.overlineCalenderTag {
  font-family: "Futura Std Medium Condensed" !important;
}

.login {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  color: black !important;
}

.ck-editor {
  font-family: "Futura Std Book" !important;
}

.monoFont,
.codeText,
.red-text,
.green-text,
.ant-btn,
td {
  font-family: "Futura Std Medium" !important;
}

h1,
h2,
h3,
h4,
h5,
Title {
  font-family: "Futura Std Heavy" !important;
}

th {
  font-family: "Futura Std Bold Condensed";
  color: black !important;
}

td {
  color: black !important;
}

.overlineCalenderTag {
  margin-left: -12px;
  font-size: 10px !important;
  font-weight: 400 !important;
  letter-spacing: 0.33px !important;
  text-transform: uppercase !important;
}

.ant-btn {
  text-transform: uppercase !important;
}

.student-circle .sunburst-wrapper {
  margin-top: 4%;
}

.userPageHeader .ant-page-header,
.ant-card-head {
  background: #0d3660 !important;
  color: white !important;
}

.userPageHeader .ant-page-header * {
  color: white !important;
}

.ant-statistic-title,
.ant-typography,
.ant-card-meta-title,
.cke_editable,
.ant-list-item-meta-description {
  color: black !important;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: none;
  border-bottom: 0;
  background: black;
  color: white;
  font-weight: 700 !important;
  padding: 7px 8px !important;
}

.ant-checkbox-wrapper-checked .ant-card {
  background-color: #eff2f5 !important;
}

.fullScreen2 {
  padding: 0.3%;
  height: 100vh;
  width: auto;
  border: 0;
  margin: 0;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
  border: 5px dashed firebrick;
}

.hide {
  display: none !important;
}

/*
.tooltitle {
  font-family: "Futura Std Heavy";
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 1px;
}
*/
.red-text {
  color: white;
  background-color: #fe603b !important;
}

.green-text {
  color: white;
  background-color: rgb(63, 134, 0) !important;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 70vh;
  zoom: 95%;
}

#session .ant-card-extra {
  width: 30% !important;
}

.cText {
  font-size: 12px !important;
}

.ant-drawer-content .ant-list-item-meta-description {
  text-align: justify !important;
}

.ant-layout {
  height: 100% !important;
}

.ant-layout-sider-children {
  height: 100vh !important;
}

/*
.ant-page-header,
.ant-card-head {
  background: #0a4262 !important;
  border-color: #0a4262 !important;
}

.ant-page-header {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.greenblue {
  background-color: #0e566c !important;
}

.course-card .ant-card-head {
  background-color: #0a4262 !important;
  border-color: #0a4262 !important;
  color: white !important;
}

.ant-page-header-back-button {
  color: white !important;
}

.ant-card-head {
  color: white !important;
  font-weight: 700 !important;
  border-radius: 6px 6px 0 0 !important;
}

.ant-page-header-heading-title {
  color: white !important;
}

.ant-card {
  color: #34495e;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 8px !important;
}

.ant-breadcrumb,
.g2-tooltip {
  font-family: "Futura Std Medium" !important;
}

.ant-tabs-nav-list {
  font-family: "Futura Std Heavy";
}

canvas,
.ant-descriptions-item-label {
  font-family: "Futura Std Medium Condensed" !important;
}

.ant-card-actions {
  background: transparent !important;
}
*/

.apps .ant-popover {
  width: 301px !important;
  overflow: auto !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.capps .ant-space-item {
  margin-top: 10px !important;
  color: black !important;
}

.capps p {
  color: black !important;
  margin-top: 10px !important;
}

.capps :hover {
  background: #f5f5f5 !important;
}

/*----------------------------------------
  Ace Editor
------------------------------------------*/
@media only screen and (min-width: 1000px) {
  .ace-editor {
    height: 45vh !important;
    overflow-y: scroll;
  }
}

.ace_editor {
  width: 100% !important;
}

.codeResult {
  padding: 18px 32px !important;
}

.ace_autocomplete {
  width: 20% !important;
}

.codeResult .ant-result-icon {
  margin-bottom: 7px !important;
}

/*----------------------------------------
  Helper Classes
------------------------------------------*/
pre {
  margin-left: 3px;
  line-height: 16px;
  white-space: pre-wrap;
  display: block;
  font-size: 0.9em !important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.codeText1 {
  font-size: 0.9em !important;
  line-height: 13px !important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overline {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.06333em;
  text-transform: uppercase;
}

.overlineStars .ant-card-meta-title {
  font-size: 9px !important;
  font-weight: 400;
  letter-spacing: 0.02333em;
  text-transform: uppercase;
  text-align: center;
}

.overlineTLC {
  font-size: 0.7rem;
  color: #a2b4c5 !important;
  font-weight: 400;
  letter-spacing: 0.199em;
  text-transform: uppercase;
}

.justify {
  text-align: justify;
}

/* Width */
.width-10 {
  width: 10% !important;
}

.width-20 {
  width: 20% !important;
}

.width-30 {
  width: 30% !important;
}

.width-40 {
  width: 40% !important;
}

.width-50 {
  width: 50% !important;
}

.width-60 {
  width: 60% !important;
}

.width-70 {
  width: 70% !important;
}

.width-80 {
  width: 80% !important;
}

.width-90 {
  width: 90% !important;
}

.width-100 {
  width: 100% !important;
}

/* Border Radius */
.border-radius-1 {
  border-radius: 1px !important;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-7 {
  border-radius: 7px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-9 {
  border-radius: 9px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-round {
  border-radius: 150px !important;
}

/* Font Weight */
.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.m-0 {
  margin: 0 !important;
}

/* Margin Top */
.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1% !important;
}

.mt-2 {
  margin-top: 2% !important;
}

.mt-3 {
  margin-top: 3% !important;
}

.mt-4 {
  margin-top: 4% !important;
}

.mt-5 {
  margin-top: 5% !important;
}

.mt-6 {
  margin-top: 6% !important;
}

.mt-7 {
  margin-top: 7% !important;
}

.mt-8 {
  margin-top: 8% !important;
}

.mt-9 {
  margin-top: 9% !important;
}

.mt-10 {
  margin-top: 10% !important;
}

/* Margin Right */
.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 1% !important;
}

.mr-2 {
  margin-right: 2% !important;
}

.mr-3 {
  margin-right: 3% !important;
}

.mr-4 {
  margin-right: 4% !important;
}

.mr-5 {
  margin-right: 5% !important;
}

.mr-6 {
  margin-right: 6% !important;
}

.mr-7 {
  margin-right: 7% !important;
}

.mr-8 {
  margin-right: 8% !important;
}

.mr-9 {
  margin-right: 9% !important;
}

.mr-10 {
  margin-right: 10% !important;
}

/* Margin Left */
.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 1% !important;
}

.ml-2 {
  margin-left: 2% !important;
}

.ml-3 {
  margin-left: 3% !important;
}

.ml-4 {
  margin-left: 4% !important;
}

.ml-5 {
  margin-left: 5% !important;
}

.ml-6 {
  margin-left: 6% !important;
}

.ml-7 {
  margin-left: 7% !important;
}

.ml-8 {
  margin-left: 8% !important;
}

.ml-9 {
  margin-left: 9% !important;
}

.ml-10 {
  margin-left: 10% !important;
}

/* Margin Bottom */
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1% !important;
}

.mb-2 {
  margin-bottom: 2% !important;
}

.mb-3 {
  margin-bottom: 3% !important;
}

.mb-4 {
  margin-bottom: 4% !important;
}

.mb-5 {
  margin-bottom: 5% !important;
}

.mb-6 {
  margin-bottom: 6% !important;
}

.mb-7 {
  margin-bottom: 7% !important;
}

.mb-8 {
  margin-bottom: 8% !important;
}

.mb-9 {
  margin-bottom: 9% !important;
}

.mb-10 {
  margin-bottom: 10% !important;
}

/* Padding All */
.padding-1 {
  padding: 1% !important;
}

.padding-2 {
  padding: 2% !important;
}

.padding-3 {
  padding: 3% !important;
}

.padding-4 {
  padding: 4% !important;
}

.padding-5 {
  padding: 5% !important;
}

.padding-6 {
  padding: 6% !important;
}

.padding-7 {
  padding: 7% !important;
}

.padding-8 {
  padding: 8% !important;
}

.padding-9 {
  padding: 9% !important;
}

.padding-10 {
  padding: 10% !important;
}

.p-0 {
  padding: 0 !important;
}

/* Padding Top */
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 1% !important;
}

.pt-2 {
  padding-top: 2% !important;
}

.pt-3 {
  padding-top: 3% !important;
}

.pt-4 {
  padding-top: 4% !important;
}

.pt-5 {
  padding-top: 5% !important;
}

.pt-6 {
  padding-top: 6% !important;
}

.pt-7 {
  padding-top: 7% !important;
}

.pt-8 {
  padding-top: 8% !important;
}

.pt-9 {
  padding-top: 9% !important;
}

.pt-10 {
  padding-top: 10% !important;
}

/* Padding Right */
.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 1% !important;
}

.pr-2 {
  padding-right: 2% !important;
}

.pr-3 {
  padding-right: 3% !important;
}

.pr-4 {
  padding-right: 4% !important;
}

.pr-5 {
  padding-right: 5% !important;
}

.pr-6 {
  padding-right: 6% !important;
}

.pr-7 {
  padding-right: 7% !important;
}

.pr-8 {
  padding-right: 8% !important;
}

.pr-9 {
  padding-right: 9% !important;
}

.pr-10 {
  padding-right: 10% !important;
}

/* Padding Right */
.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 1% !important;
}

.pl-2 {
  padding-left: 2% !important;
}

.pl-3 {
  padding-left: 3% !important;
}

.pl-4 {
  padding-left: 4% !important;
}

.pl-5 {
  padding-left: 5% !important;
}

.pl-6 {
  padding-left: 6% !important;
}

.pl-7 {
  padding-left: 7% !important;
}

.pl-8 {
  padding-left: 8% !important;
}

.pl-9 {
  padding-left: 9% !important;
}

.pl-10 {
  padding-left: 10% !important;
}

/* Padding Bottom */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 1% !important;
}

.pb-2 {
  padding-bottom: 2% !important;
}

.pb-3 {
  padding-bottom: 3% !important;
}

.pb-4 {
  padding-bottom: 4% !important;
}

.pb-5 {
  padding-bottom: 5% !important;
}

.pb-6 {
  padding-bottom: 6% !important;
}

.pb-7 {
  padding-bottom: 7% !important;
}

.pb-8 {
  padding-bottom: 8% !important;
}

.pb-9 {
  padding-bottom: 9% !important;
}

.pb-10 {
  padding-bottom: 10% !important;
}

/* Minimum Height */
.min-height-100 {
  min-height: 100px !important;
}

.min-height-150 {
  min-height: 150px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-250 {
  min-height: 250px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.course-img {
  opacity: 0.8 !important;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
