/* Font2 */

@font-face {
  font-family: "Futura Std Book";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Book"), url("./font/FuturaStdBook.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Book Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Book Oblique"),
    url("./font/FuturaStdBookOblique.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Light Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Light Condensed"),
    url("./font/FuturaStdCondensedLight.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Light";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Light"),
    url("./font/FuturaStdLight.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Heavy"),
    url("./font/FuturaStdHeavy.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Medium Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Medium Condensed"),
    url("./font/FuturaStdCondensed.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Medium"),
    url("./font/FuturaStdMedium.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Heavy"),
    url("./font/FuturaStdHeavy.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Futura Std Bold Condensed"),
    url("./font/FuturaStdCondensedBold.woff") format("woff");
}

@font-face {
  font-family: "droid_sans_monoregular";
  src: url("./font/droid/droidsansmono-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "euclid_circular";
  src: url("./font/droid/euclidcirculara-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}